import React, { useState, useEffect } from "react";
import axios from "axios";

const Dashboard = () => {
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [creditAmount, setCreditAmount] = useState("");
  const [message, setMessage] = useState("");
  const [paymentData, setPaymentData] = useState("");
  const [walletData, setWalletData] = useState("");

  // Fetch users data
  // Fetch users and payment data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [userResponse, paymentResponse, walletResponse] =
          await Promise.all([
            axios.get(
              `${process.env.REACT_APP_API_URL}/api/dashboard/user-data`
            ),
            axios.get(
              `${process.env.REACT_APP_API_URL}/api/dashboard/payment-data?startDate=2024-11-01&endDate=2024-12-31`
            ),
            // axios.get(
            //   `${process.env.REACT_APP_API_URL}/api/dashboard/wallet-data?startDate=2024-11-01&endDate=2024-12-31`
            // ),
          ]);
        //setWalletData(walletResponse.data);
        setUserData(userResponse.data);
        setPaymentData(paymentResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p className="text-center">Loading user data...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  // const groupedData = walletData.reduce((acc, transaction) => {
  //   const provider = transaction._id.provider;
  //   const type = transaction._id.type;
  //   const amount = parseFloat(transaction.totalAmount.$numberDecimal);

  //   // Initialize the provider in the accumulator if not already present
  //   if (!acc[provider]) {
  //     acc[provider] = { transactions: [], profit: 0 };
  //   }

  //   // Add the transaction to the provider's list
  //   acc[provider].transactions.push(transaction);

  //   // Calculate profit based on type and provider
  //   if (type === "DEBIT") {
  //     acc[provider].profit += provider === "ifuse" ? -amount : amount;
  //   } else if (type === "CREDIT") {
  //     acc[provider].profit -= amount;
  //   }

  //   return acc;
  // }, {});

  return (
    <div className="max-w-5xl mx-auto p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Dashboard</h2>

      {/* Search Input */}

      {/* Success/Error Message */}
      {message && <p className="text-center text-green-500">{message}</p>}

      <div className="mb-8 border rounded-lg p-4 shadow">
        {/* User Details Table */}
        <table className="min-w-full bg-white border border-gray-200">
          <tbody>
            <tr className="border-t">
              <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                สมัครทั้งหมด
              </th>
              <td className="px-4 py-2">{userData.total}</td>
            </tr>
            <tr className="border-t">
              <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                เพิ่มบัญชี
              </th>
              <td className="px-4 py-2">{userData.withBank}</td>
            </tr>
            <tr className="border-t">
              <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                ยังไม่เพิ่มบัญชี
              </th>
              <td className="px-4 py-2">{userData.withoutBank}</td>
            </tr>
            {/* Additional rows for other details */}
          </tbody>
        </table>
      </div>
      <div className="mb-8 border rounded-lg p-4 shadow">
        {/* User Details Table */}
        <table className="min-w-full bg-white border border-gray-200">
          <tbody>
            <tr className="border-t">
              <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                ยอดฝากทั้งหมด (DEPOSIT)
              </th>
              <td className="px-4 py-2">
                {paymentData
                  .find((item) => item._id === "DEPOSIT")
                  ?.totalAmount.toString() || "0"}
              </td>
            </tr>
            <tr className="border-t">
              <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                ยอดถอนทั้งหมด (WITHDRAWAL)
              </th>
              <td className="px-4 py-2">
                {paymentData
                  .find((item) => item._id === "WITHDRAW")
                  ?.totalAmount.toString() || "0"}
              </td>
            </tr>
            <tr className="border-t">
              <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                Profit
              </th>
              <td className="px-4 py-2">
                {(() => {
                  const totalDeposit =
                    paymentData.find((item) => item._id === "DEPOSIT")
                      ?.totalAmount || 0;
                  const totalWithdraw =
                    paymentData.find((item) => item._id === "WITHDRAW")
                      ?.totalAmount || 0;

                  const profit = totalDeposit - totalWithdraw;
                  return profit.toString(); // Display profit as a string
                })()}
              </td>
            </tr>
            {/* Additional rows for other details */}
          </tbody>
        </table>
      </div>

      {/* Modal for Adding Credit */}
    </div>
  );
};

export default Dashboard;
